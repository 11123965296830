import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';
import { RidiColors } from '@/components/styles/RidiColors';
import { RIDITheme } from '@/components/styles/themes';

export const sectionTitleStyle = css`
  font-weight: normal;
  height: 21px;
  line-height: 21px;
  font-size: 19px;
  margin-bottom: 20px;
  a {
    color: ${RidiColors.black};
  }
`;

export const keywordListStyle = css`
  display: flex;
  flex-wrap: wrap;
  margin: -3px;
  margin-top: -1px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-top: -2px;
    `,
  )};
`;

export const keywordStyle = (theme: RIDITheme): SerializedStyles => css`
  display: inline-block;
  border: 1px solid ${theme.colors.grey300};
  border-radius: 3px;

  flex: none;
  padding: 6px 8px 7px 8px;
  margin: 4px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 6px 9px;
    `,
  )};
`;

export const keywordAnchorStyle = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.grey600};

  font-size: 16px;
  font-weight: 400;
  line-height: 19px;

  &::before {
    display: inline;
    content: '#';
  }

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 15px;
      line-height: 18px;
    `,
  )};
`;
