import { decode, encode, ParsedUrlQuery } from 'querystring';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TrackClickEvent } from '@/components/common/EventClient/TrackClickEvent';
import { SectionLayout } from '@/components/genreHome/common/SectionLayout';
import { useSectionTrackingDataContext } from '@/components/genreHome/common/SectionTrackingDataContextProvider';
import { SectionByLayout } from '@/features/genreHome/views/utils/viewDataUtils';
import { viewSelector } from '@/features/genreHome/views/viewsSlice';
import { SectionLayoutType } from '@/models/backendsApi/v2/Views/ViewsType';

import * as styles from './Keyword.styles';

export const GENRE_MAP: Record<string, string> = {
  general: 'general',
  comics: 'comic',
  webtoon: 'comic',
  fantasy: 'fantasy',
  romance: 'romance',
  'fantasy-serial': 'fantasy',
  'romance-serial': 'romance',
  'bl-webnovel': 'bl',
  'bl-novel': 'bl',
  'bl-comics': 'bl',
  'bl-webtoon': 'bl',
  comic_serial: 'comic',
  romance_fantasy: 'romance',
  romance_fantasy_serial: 'romance',
  geukhwa: 'comic',
};
export interface KeywordProps {
  section: SectionByLayout<SectionLayoutType.Keyword>;
}

export const Keyword = ({ section }: KeywordProps): ReactJSX.Element => {
  const contents = useSelector(viewSelector)?.contents;
  const genre = contents?.genre;
  const setId = contents?.set_id;
  const parentGenre = (genre && GENRE_MAP[genre]) || genre;

  const sectionTrackingData = useSectionTrackingDataContext();
  const landingURL = useMemo(() => {
    if (!parentGenre || !genre) {
      return null;
    }

    const baseURL = `/keyword-finder/${parentGenre}`;

    const queryObject: ParsedUrlQuery = {};

    queryObject.from = genre;

    if (setId) {
      queryObject.set_id = setId.toString();
    }

    return `${baseURL}?${encode(queryObject)}`;
  }, [parentGenre, genre, setId]);

  const keywords = useMemo(
    () =>
      landingURL &&
      section.items.map(item => {
        const [subPath, searchParams] = landingURL.split('?');
        const queryObject = decode(searchParams);

        queryObject['tag_ids[]'] = item.contents.tag_id.toString();

        if (item.contents.set_id) {
          queryObject.set_id = item.contents.set_id.toString();
        }

        const href = `${subPath}?${encode(queryObject)}`;
        return {
          id: item.id,
          name: item.contents.name,
          href,
          params: {
            ...sectionTrackingData.params,
            keyword_title: item.contents.name,
            keyword_id: item.id,
          },
        };
      }),
    [landingURL, sectionTrackingData.params, section.items],
  );

  if (!keywords || keywords.length === 0) {
    return <></>;
  }

  return (
    <SectionLayout title={section.title} horizontal={false} link={landingURL}>
      <ul css={styles.keywordListStyle}>
        {keywords.map(keyword => (
          <li css={styles.keywordStyle} key={keyword.id}>
            <TrackClickEvent screenName={sectionTrackingData.screenName} target="keyword" params={keyword.params}>
              <a href={keyword.href} css={styles.keywordAnchorStyle}>
                {keyword.name}
              </a>
            </TrackClickEvent>
          </li>
        ))}
      </ul>
    </SectionLayout>
  );
};
